<template>
  <div  class="deviceMQBGView">
    <div class="deviceMQTab">
      <div class="dateBGView">
        <div class="dateTitle" @click="dateChange(-1)">前一天</div>
        <div class="dateHead">
          <el-date-picker
              v-if="timeType=='day'"
              value-format="timestamp"
              v-model="startTime"
              type="date"
              placeholder="选择日期"
          >
          </el-date-picker>
          <el-time-select
              v-if="timeType=='hour'"
              placeholder="起始时间"
              v-model="hourTime"
              :picker-options="{
                start: '00:00',
                step: '00:1',
                end: '24:00'
              }"
          >
          </el-time-select>

          <div class="dateStr">{{ dateStr }}</div>
        </div>
        <div class="dateTitle" @click="dateChange(1)">后一天</div>
      </div>

      <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item) in tabList"
                     :disabled="item.router==''"
                     :name="item.title"
                     :key="item.title"
        >
        <span slot="label">
          <h-stack :key="item.title">
            <div style="float: left;padding: 0px 5px 0px 5px">{{ item.title }}</div>
            <el-badge v-if="item.number>0" :value="item.number" size="mini" class="item"></el-badge>
          </h-stack>
        </span>
          <!--          -->
          <el-tabs :stretch="true" v-model="subActiveName" @tab-click="subHandleClick">
            <el-tab-pane v-for="(subTtem) in subTabList"
                         :disabled="subTtem.router==''"
                         :name="subTtem.title"
                         :key="subTtem.title"
            >
              <span slot="label">
                <h-stack :key="subTtem.title">
                  <div style="float: left;padding: 0px 5px 0px 5px">{{ subTtem.title }}</div>
                  <el-badge v-if="subTtem.number>0" :value="subTtem.number" size="mini" class="item"></el-badge>
                </h-stack>
              </span>

              <router-link :to="subTtem.router"></router-link>
              <router-view
                  :id="loadingDivId"
                  style="height: calc(100% - 55px);"
                  v-if="subTtem.show&&(subTtem.type==item.type)"
                  v-on:loadingChange="loadingChange"
                  v-bind:view-style="viewType"
                  v-bind:query="subTtem.query"
                  v-bind:show="subTtem.show&&!loading"
                  v-bind:search-info="fillterConfig"
                  v-on:stopRefresh="stopRefresh"
                  v-on:updateNumber="updateNumber"
              ></router-view>

            </el-tab-pane>
          </el-tabs>




          <!--          -->


        </el-tab-pane>
      </el-tabs>

    </div>
    <fillter-bar
        :fillter-key-info="{'viewType':'', 'timeType':'', 'time':'', 'station':'', 'device':''}"
        :fillter-info="fillterInfo"
        @delayRefresh="delayRefresh"
        @refresh="refresh"
        @showFillter="showFillter"
        @startRefresh="startRefresh"
    >
    </fillter-bar>

    <el-drawer
        size="70%"
        class="fillterView"
        :with-header="false"
        :visible.sync="showFillterView"
        direction="rtl"
    >
      <fillter-view ref="fillterView" :view-config="this.fillterViewTypeConfig" @updateSearch="updateSearch"></fillter-view>
    </el-drawer>

  </div>
</template>

<script>

import deviceMQMixin from '@/views/deviceMQ/MQmixin/deviceMQMixin'
import FillterBar from '@/views/deviceMQ/fillterBar/fillterBar'
import HStack from '@/components/hStack/hStack'
import FillterView from '@/views/deviceMQ/fillterView/fillterView'

export default {
  name: 'station-MQ',
  components: { FillterView, HStack, FillterBar },
  mixins: [deviceMQMixin],
  data() {
    return {
      loadingDivId:'station-MQ',
      viewType: 'list', // list 列表  rssi 图表
      timeType: 'day', // day 按天  hour 按小时
      startTime: new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime(),
      hourTime:'00:00',
      query: {},
      subTabList: [],
      subActiveName: ''
    }
  },
  watch: {
    hourTime: {
      handler(value) {
        let str = (this.formatDayDate(this.startTime) + ' ' + value).replace(/-/g,'/')
        let timestamp = new Date(str).getTime();
        this.startTime = timestamp;
      },
      immediate: true,
      deep: true
    },
    startTime: {
      handler(value) {
        if (this.loading == true) {
          return
        }
        this.getTabList().then(res => {
          if (res.result == false) {
            return
          }
          this.getFillterInfo()
          this.afterGetTabList()
        })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    fillterViewTypeConfig:function() {
      let config = {
        viewStyle:'',
        timeStyle:'',
        selectTime: '',
        selectDevice: ''
      }
      let type = this.getDeviceType();
      if (type == 2) {
        config['showMega'] = '';
      }
      return config
    },
    dateStr: function() {
      function getWeek(date) {
        let week
        if (date.getDay() == 0) week = '星期日'
        if (date.getDay() == 1) week = '星期一'
        if (date.getDay() == 2) week = '星期二'
        if (date.getDay() == 3) week = '星期三'
        if (date.getDay() == 4) week = '星期四'
        if (date.getDay() == 5) week = '星期五'
        if (date.getDay() == 6) week = '星期六'
        return week
      }

      let str = ''

      let time = this.startTime
      str = this.formatDayDate(time) + ' ' + (this.timeType == 'day' ? '' : this.hourTime)
      return str + ' (' + getWeek(new Date(str)) + ')'
    }
  },
  mounted() {
    this.query = JSON.parse(JSON.stringify(this.$route.query))
  },
  methods: {
    afterUpdateing: function() {
      for (let i = 0; i < this.fillterInfo.fillterList.length; i++) {
        let info = this.fillterInfo.fillterList[i]
        if (info.type == 'timeType') {
          this.timeType = info.id
        } else if (info.type == 'viewType') {
          this.viewType = info.id
        }
      }
    },
    // 点击tab
    subHandleClick(tab, event) {
      for (let i = 0; i < this.subTabList.length; i++) {
        let info = this.subTabList[i]
        if (info.subActiveName == info.title) {
          return
        }
        info.show = this.subActiveName == info.title
        if (info.show) {
          this.$router.push(info.router)
        }
      }
    },
    afterGetTabList: function() {
      this.updateStyle()
      this.updateSubTabList().then(res => {
        this.subTabList = res.dataList
        for (let i = 0; i < this.subTabList.length; i++) {
          let info = this.subTabList[i]
          if (i == 0) {
            info.show = true
            this.subActiveName = info.title
            if (this.$route.path != info.router) {
              this.$router.replace(info.router)
            }
          } else {
            info.show = false;
          }
        }
      })
    },
    updateSubTabList: function() {
      return new Promise(resolve => {
        if (typeof this.query['includeBaseStationMac'] == 'undefined') {
          resolve({ dataList: [] })
          return
        }
        let query = {}
        query['stationMac'] = this.query['includeBaseStationMac']

        for (const queryKey in this.query) {
          query[queryKey] = this.query[queryKey]
        }
        query['deviceType'] = this.getDeviceType()
        let startTime = this.startTime
        let endTime = this.startTime + 1 * 24 * 3600 * 1000
        query['startTime'] = startTime
        query['endTime'] = endTime
        let excludeDeviceMac = ''
        let includeDeviceMac = ''
        for (let i = 0; i < this.fillterInfo.fillterList.length; i++) {
          let data = this.fillterInfo.fillterList[i]
          if (data.type != 'device') {
            continue
          }
          if (data.status == 1) {
            includeDeviceMac = includeDeviceMac + (includeDeviceMac == '' ? '' : ',') + data.id
          } else if (data.status == 2) {
            excludeDeviceMac = excludeDeviceMac + (excludeDeviceMac == '' ? '' : ',') + data.id
          }
        }
        query['excludeDeviceMac'] = excludeDeviceMac
        query['includeDeviceMac'] = includeDeviceMac

        this.$api.deviceMQ.getStationDetail(query).then(res => {
          let dataList = []
          if (res.code != 200) {
            resolve({ dataList: dataList })
            return
          }

          let router = ''
          if (query.deviceType == 1) {
            router = '/station-MQ/Z2View'
          } else if (query.deviceType == 2) {
            router = '/station-MQ/Z3View'
          } else if (query.deviceType == 3) {
            router = '/station-MQ/Z4View'
          } else if (query.deviceType == 4) {
            router = '/station-MQ/Z5View'
          }

          let titleKey = ''
          if (query.deviceType == '1' || query.deviceType == '2' || query.deviceType == '3' || query.deviceType == '4') {
            titleKey = 'deviceMac'
          }

          for (let i = 0; i < res.data.length; i++) {
            let dataInfo = res.data[i]
            let title = dataInfo[titleKey]
            let number = 0
            if (typeof dataInfo['num'] != 'undefined' && dataInfo['num'] != null) {
              number = dataInfo['num']
            }
            let itemInfo = {
              title: title,
              number: number,
              router: router,
              show: false,
              query: {
                includeMac: title,
                startTime: startTime,
                endTime: endTime,
                time: 1 * 24 * 3600 // 将时间定为一天
              },
              type:query.deviceType,
            }
            dataList.push(itemInfo)
          }
          dataList.sort((a, b) => {
            return b.number - a.number
          })
          resolve({ dataList: dataList })

        }).catch(() => {
          resolve({ dataList: [] })
        })
      })
    },
    getDeviceType: function() {
      let type = 1
      for (let i = 0; i < this.tabList.length; i++) {
        let info = this.tabList[i]
        if (info.show) {
          type = info.type
          break
        }
      }
      return type
    },
    changeTime: function() {
      this.startTime = this.hourTime;
    },
    // 前一天/后一天
    dateChange: function(value) {
      this.startTime = this.startTime + 1 * 24 * 3600 * 1000 * value
    },
    updateTabList: function(callBack) {
      let query = JSON.parse(JSON.stringify(this.query))
      let startTime = this.startTime
      let endTime = this.startTime + 1 * 24 * 3600 * 1000
      query['startTime'] = startTime
      query['endTime'] = endTime
      this.$api.deviceMQ.getDeviceTotalCount(query).then(res => {
        let dataList = []
        if (res.code != 200) {
          callBack({ dataList: dataList })
          return
        }

        for (const key in res.data) {
          let title = ''
          let router = '/station-MQ'
          let order = 0
          let type = 1
          if (key == 'z2s') {
            title = 'Z2'
            order = 1
            type = 1
          } else if (key == 'z3s') {
            title = 'Z3'
            order = 2
            type = 2
          } else if (key == 'z4s') {
            title = 'Z4'
            order = 3
            type = 3
          } else if (key == 'z5s') {
            title = 'Z5'
            order = 4
            type = 4
          } else if (key == 'braceletInfos') {
            title = '手环/胸卡'
            order = 5
            type = 5
          } else if (key == 'incubatorInfos') {
            title = '温箱'
            order = 6
            type = 6
          } else if (key == 's2BraceletInfos') {
            title = 'S2'
            order = 7
            type = 7
          } else {
            continue;
          }
          let number = 0
          if (typeof res.data[key] != 'undefined' && res.data[key] != null) {
            number = res.data[key]
          }
          let itemInfo = {
            title: title,
            number: number,
            router: router,
            show: false,
            order: order,
            type: type
          }
          dataList.push(itemInfo)
        }

        dataList.sort((a, b) => {
          return a.type - b.type
        })
        callBack({ dataList: dataList })
      }).catch(() => {
        callBack({ dataList: [] })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMQBGView {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  .fillterView {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .deviceMQTab {
    width: 100%;
    height: calc(100% - 100px);

    .dateBGView {
      width: 100%;
      height: 50px;
      background-color: white;
      border-bottom: 1px gainsboro solid;

      .dateTitle {
        float: left;
        width: calc(50% - 110px);
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: red;
      }

      .dateHead {
        float: left;
        width: 220px;
        height: 50px;

        .dateStr {
          width: 100%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: red;
          z-index: 100;
          transform: translate(0px, -40px);
          pointer-events: none;
          background-color: white;
          border-bottom: 1px gainsboro solid;
        }

      }

    }


    .el-tabs {
      height: 100%;

      .el-tabs__content {


        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
}

</style>
